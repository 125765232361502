<template>
  <div class="login-form px-20 mdmax:px-0">
    <div class="text-2xl font-bold mb-8">Clouducation Event Registration</div>
    <div class="mb-6">
      <TextField type="text" v-model="requiredPayload.fullName" :borderEnabled="true" placeholder="Input your full name" label="Full Name" :error="errors['fullName']" />
    </div>
    <div class="mb-6">
      <TextField type="text" v-model="requiredPayload.email" :borderEnabled="true" placeholder="Input your email address" label="Email Address" :error="errors['email']" />
    </div>
    <div class="mb-6">
      <TextField type="text" v-model="requiredPayload.phoneNumber" :borderEnabled="true" placeholder="Input your phone number" label="Phone Number" :error="errors['phoneNumber']" />
    </div>
    <div class="mb-6">
      <label class="text-xs pl-3 text-left block mb-1">Ticket Type</label>
      <div class="relative">
        <span class="inline-block absolute right-5 top-1/2 transform -translate-y-1/2 leading-0 z-0"><Chevron direction="down" width="16" height="16" /></span>
        <select v-model="ticketType" class="border border-grey-field pl-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full rounded-lg appearance-none bg-transparent relative z-1">
          <option :value="ticket.value" v-for="(ticket, index) in ticketOptions" :key="'ticket-' + index">{{ ticket.name }}</option>
        </select>
      </div>
      <p class="text-xs text-left mt-2 text-neutral-400" v-if="ticketType === 'student'">You will be contacted by admin to verify your student card</p>
    </div>
    <div class="mb-6">
      <label class="text-xs pl-3 text-left block mb-1">Social Media (optional)</label>
      <div class="relative">
        <span class="inline-block absolute right-5 top-1/2 transform -translate-y-1/2 leading-0 z-0"><Chevron direction="down" width="16" height="16" /></span>
        <select v-model="socialMediaType" class="border border-grey-field pl-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full rounded-lg appearance-none bg-transparent relative z-1">
          <option :value="social" v-for="(social, index) in socialMediaOptions" :key="'ticket-' + index">{{ social }}</option>
        </select>
      </div>
    </div>
    <div class="mb-6">
      <TextField type="text" v-model="socialMedia" :borderEnabled="true" placeholder="Input your social media account" label="Social Media Account" />
    </div>
    <div class="mb-8 text-left flex items-center gap-3">
      <div class="">
        <input type="checkbox" class="styled-checkbox" id="tnc" name="tnc" v-model="checkedTnc" />
        <label for="tnc">&nbsp;</label>
      </div>
      <p>I agree to <span class="text-yellow cursor-pointer" @click="showTnC()">Terms and Conditions</span></p>
    </div>
    <div class="text-center">
      <p class="text-system-error text-sm mb-6" v-if="generalError">{{ generalError }}</p>
      <Button buttonText="Register" size="big" @action="register()" :disabled="!checkedTnc" />
    </div>
  </div>
</template>
<script>
import { isNotEmptyError } from '@/utils'
import { mapActions } from 'vuex'
const CONFIG = {
  email: 'Email',
  fullName: 'Full Name',
  phoneNumber: 'Phone number'
}
export default {
  components: {
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron')
  },
  props: {
    coreClassId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    requiredPayload: {
      fullName: null,
      email: null,
      phoneNumber: null
    },
    ticketType: 'general',
    socialMediaType: null,
    socialMedia: null,
    checkedTnc: false,
    errors: [],
    generalError: '',
    ticketOptions: [
      { name: 'Regular', value: 'general' },
      { name: 'Student', value: 'student' }
    ],
    socialMediaOptions: ['Facebook', 'Instagram', 'LinkedIn']
  }),
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('program', ['enrollV2']),
    userIsTyping() {
      if (this.password.length > 0) {
        this.isTyping = true
        return
      }
      this.isTyping = false
    },
    showPassword(status) {
      status ? (this.typeInput = 'text') : (this.typeInput = 'password')
    },
    showTnC() {
      this.$emit('showtnc')
    },
    register() {
      this.errors = []
      if (this.validForm()) {
        // eslint-disable-next-line
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
        if (!regex.test(this.requiredPayload.email)) {
          this.errors['email'] = 'Email format is invalid!'
        } else {
          this.showLoading()
          const payloads = {
            customerId: 3,
            formCode: '03',
            name: this.requiredPayload.fullName,
            email: this.requiredPayload.email,
            mobilePhone: this.requiredPayload.phoneNumber,
            programCode: '',
            socialMedia: this.socialMediaType,
            socialMediaAccount: this.socialMedia,
            coreClassId: this.coreClassId,
            priceCategory: this.ticketType
          }
          this.enrollV2({
            payloads: payloads
          })
            .then(() => {
              this.hideLoading()
              this.$router.push('/clouducation/thankyou')
            })
            .catch((res) => {
              this.hideLoading()
              if (res.response.status === 400) {
                this.generalError = 'The email address you have entered is already registered.'
              }
            })
        }
      }
    },
    validForm() {
      const error = isNotEmptyError(this.requiredPayload)
      this.errors = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      return Object.keys(this.errors).length === 0
    }
  }
}
</script>
<style lang="scss" scoped>
.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    // margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #3b82f6;
    border-radius: 4px;
  }

  // Box hover
  &:hover + label:before {
    background: #3b82f6;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: #3b82f6;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
</style>
